import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Estilo para el documento PDF
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        paddingLeft: 2,
        paddingRight:2,
        paddingBottom: 5,
        paddingTop:5
    },
    section: {
        margin: 5,
        padding: 5,
    },
    containerStyles: {
        display: 'flex',
        textAlign: 'center', // Centrado del texto dentro de los elementos de texto
    },
    totalStyles: {
        display: 'flex',
        textAlign: 'right', // Centrado del texto dentro de los elementos de texto
    },
    textNameStyles: {
        fontSize: 24
    },
    table: {
        display: "table",
        width: "85%",
        margin: "0 auto", // Centra la tabla
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableItems: {
        display: "table",
        width: "100%",
        margin: "0 auto", // Centra la tabla
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableColFecha: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColMarca: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColPlaca: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColChofer: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColLicencia: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColSucursal: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColGuia: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColDestinatario: {
        width: "35%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColDescripcion: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColPrecio: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: 5,
        fontSize: 8,
        textAlign: 'center', // Centrar el texto
    },
    tableHeader: {
        fontWeight: 'bold', // Hacer el texto en negrita
    },
    line: {
        borderBottomWidth: 0.3,
        borderBottomColor: '#000000',
        marginBottom: 0.5,
    },
    space: {
        marginBottom: 20, // Ajusta este valor para cambiar el espacio
    },
    image: {
        width: 100, // Ajusta el ancho según necesites
        height: 100, // Ajusta la altura según necesites
        marginBottom: 20, // Margen inferior para separar de otros elementos
    }
});

// Componente del PDF
export default function DocumentIngresos(props) {
    const sumaTotal  = props.data.map(item => parseFloat(item.monto)).reduce((acc, cur) => acc + cur, 0);;
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.containerStyles}>
                        <Text style={{ fontSize: 24 }}>TRANPORTES MELLIZO EXPRESS</Text>
                        <Text style={{ fontSize: 12 }}>
                            {"\n"} REPORTE DE INGRESOS:  S/.{sumaTotal} {"\n"}  {"\n"}
                        </Text>
                    </View>
                    <View style={styles.tableItems}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColGuia}>
                                <Text style={[styles.tableCell, styles.tableHeader]}>Fecha</Text>
                            </View>
                            <View style={styles.tableColGuia}>
                                <Text style={[styles.tableCell, styles.tableHeader]}>Guia Transp.</Text>
                            </View>
                            <View style={styles.tableColDescripcion}>
                                <Text style={[styles.tableCell, styles.tableHeader]}>Cliente</Text>
                            </View>
                            <View style={styles.tableColSucursal}>
                                <Text style={[styles.tableCell, styles.tableHeader]}>Cobrador</Text>
                            </View>
                            <View style={styles.tableColSucursal}>
                                <Text style={[styles.tableCell, styles.tableHeader]}>Monto</Text>
                            </View>
                        </View>
                        {
                            props.data.map((item) => {
                                return (
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColGuia}>
                                            <Text style={[styles.tableCell]}>{item.fechaRegistro}</Text>
                                        </View>
                                        <View style={styles.tableColGuia}>
                                            <Text style={[styles.tableCell, styles.tableHeader]}>{item.serie}-{item.codigo}</Text>
                                        </View>
                                        <View style={styles.tableColDescripcion}>
                                            <Text style={[styles.tableCell, styles.tableHeader]}>{item.razonSocial}</Text>
                                        </View>
                                        <View style={styles.tableColSucursal}>
                                            <Text style={[styles.tableCell, styles.tableHeader]}>{item.cobrador}</Text>
                                        </View>
                                        <View style={styles.tableColSucursal}>
                                            <Text style={[styles.tableCell, styles.tableHeader]}>S/.{item.monto}</Text>
                                        </View>
                                    </View>
                                );
                            })
                        }
                    </View>
                </View>
            </Page>
        </Document>
    )
};

