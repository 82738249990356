import { useState, useEffect, Fragment } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ClearIcon from '@mui/icons-material/Clear';
import DateSelector from '../../components/DateSelector';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import Pagination from "@mui/material/Pagination";
import Checkbox from '@mui/material/Checkbox';

import AutoComplete from '../../components/AutoComplete';
import EnviosService from '../../services/envios';
import UnidadesService from '../../services/unidades';
import EmpleadosService from '../../services/empleados';

const EnviosCreateUpdate = ({
    title,
    token,
    isCreateDialog,
    selectedEnvio,
    setDialogOpen,
    reloadData,
    flagForReloadData,
    isSmUp,
    manageAlert,
    origenesList
}) => {

    //LUGAR ORIGEN
    const [lugarOrigenSelected, setLugarOrigenSelected] = useState(selectedEnvio ? { id: selectedEnvio.sucursal, nombre: selectedEnvio.sucursal } : null);
    const [inputOrigen, setInputOrigen] = useState('');

    //PLACA
    const [placas, setPlacas] = useState([])
    const [placaSelected, setPlacaSelected] = useState(null)
    const [inputPlaca, setInputPlaca] = useState('')

    //PLACA SECUND
    const [placaSecund, setPlacaSecund] = useState([])
    const [placaSecundSelected, setPlacaSecundSelected] = useState(null)
    const [inputPlacaSecund, setInputPlacaSecund] = useState('')

    //CHOFER
    const [choferes, setChoferes] = useState([])
    const [choferSelected, setChoferSelected] = useState(null)
    const [inputChofer, setInputChofer] = useState('')

    //PESO TOTAL
    const [pesoTotal, setPesoTotal] = useState(selectedEnvio ? selectedEnvio.totalPeso : '')

    //FILTROS
    const [fechaFilter, setFechaFilter] = useState(null)
    const [rucDestinatarioFilter, setRucDestinatarioFilter] = useState('')
    const [activateFilters, setActivateFilters] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [mercaderiaData, setMercaderiaData] = useState([]);

    //MERCADERIA
    const [mercaderiaSelected, setMercaderiaSelected] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        findAllPlacas();
        findAllChoferes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (lugarOrigenSelected !== null && lugarOrigenSelected !== '') {
            findAllMercaderia(
                currentPage - 1,
                formatDateForString(fechaFilter),
                rucDestinatarioFilter
            );
        }

    }, [currentPage, activateFilters, lugarOrigenSelected])

    const formatDateForString = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };


    const findAllPlacas = async () => {
        try {
            const responseData = await UnidadesService.getUnidadesEnvios(token);
            setPlacas(responseData);
            setPlacaSelected(selectedEnvio ? { nombre: selectedEnvio.nroPlaca } : null);

            setPlacaSecund(responseData)
            setPlacaSecundSelected(selectedEnvio ? { nombre: selectedEnvio.nroPlacaSecundaria!=null?selectedEnvio.nroPlacaSecundaria:'' } : null);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const findAllChoferes = async () => {
        try {
            const responseData = await EmpleadosService.getChoferes(token);
            setChoferes(responseData);
            setChoferSelected(selectedEnvio ? { nombre: selectedEnvio.nombreChofer } : null);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const findAllMercaderia = async (page, fecha, ruc) => {
        try {
            const responseData = await EnviosService.getMercaderia(token, page, lugarOrigenSelected.id, fecha, ruc);
            setMercaderiaData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };


    const renderTitle = (title) => {
        return (
            <DialogTitle style={{ padding: '1rem 1.25rem 0.5rem 1.25rem' }}>{title}</DialogTitle>
        )
    }

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id/* && option.descripcion === value.descripcion*/

    function handleOptionLabelNombre(objeto) {
        return objeto.nombre
    }

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size, disabled) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
                disabled={disabled}
            />
        )
    }

    const renderDividerTitle = (title) => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*padding: '1rem 1.25rem 0rem 1.25rem'*/ }}>
                <Divider textAlign="left">
                    {title}
                </Divider>
            </Grid>
        )
    }

    //ORIGEN

    const handleOrigenSelectedChange = (event, newValue) => {
        setLugarOrigenSelected(newValue ? newValue : null)
        if (newValue === null) {
            setMercaderiaData([])
            setTotalPages(0)
            handleClearFilters()
        }
        else {
            setActivateFilters(!activateFilters);
        }
    }

    const handleInputOrigenChange = (event, newValue) => {
        setInputOrigen(newValue)
    }

    const renderLugarOrigen = () => {
        return (
            <Grid item xs={12} sm={4} md={2.5} lg={2.5} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'lugarOrigen-box',
                    lugarOrigenSelected,
                    handleOrigenSelectedChange,
                    inputOrigen,
                    handleInputOrigenChange,
                    origenesList,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Lugar Origen',
                    'small',
                    !isCreateDialog
                )}
            </Grid>
        )
    }

    // PESO TOTAL EN KG

    const changeStateOnlyIfDecimalNumber = (newValue, stateFunction) => {
        if (/^\d{0,5}(\.\d{0,2})?$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const renderPeso = () => {
        return (
            <Grid item xs={5} sm={5} md={2.5} lg={2.5} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="peso-textfield"
                    label="Peso Total Kg."
                    variant="outlined"
                    value={pesoTotal}
                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, setPesoTotal)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    //CHOFER

    const handleChoferSelectedChange = (event, newValue) => {
        setChoferSelected(newValue ? newValue : null)
    }

    const handleInputChoferChange = (event, newValue) => {
        setInputChofer(newValue)
    }


    const renderChofer = () => {
        return (
            <Grid item xs={12} sm={12} md={5} lg={5} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'chofer-box',
                    choferSelected,
                    handleChoferSelectedChange,
                    inputChofer,
                    handleInputChoferChange,
                    choferes,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Chofer',
                    'small',
                    !isCreateDialog
                )}
            </Grid>
        )
    }

    //PLACA

    const handlePlacaSelectedChange = (event, newValue) => {
        setPlacaSelected(newValue ? newValue : null)
        
        setInputPlacaSecund('')
        setPlacaSecundSelected(null)
        if(newValue) {
            setPlacaSecund(placas.filter(placa => placa.id !== newValue.id))
        }
        else {
            setPlacaSecund(placas)
        }
    }

    const handleInputPlacaChange = (event, newValue) => {
        setInputPlaca(newValue)
    }


    const renderPlaca = () => {
        return (
            <Grid item xs={6} sm={4} md={2.25} lg={2.25} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'placa-box',
                    placaSelected,
                    handlePlacaSelectedChange,
                    inputPlaca,
                    handleInputPlacaChange,
                    placas,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Nro. Placa',
                    'small',
                    !isCreateDialog
                )}
            </Grid>
        )
    }

    //PLACA

    const handlePlacaSecundSelectedChange = (event, newValue) => {
        setPlacaSecundSelected(newValue ? newValue : null)
    }

    const handleInputPlacaSecundChange = (event, newValue) => {
        setInputPlacaSecund(newValue)
    }


    const renderPlacaSecund = () => {
        return (
            <Grid item xs={6} sm={4} md={2.25} lg={2.25} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'placaSecund-box',
                    placaSecundSelected,
                    handlePlacaSecundSelectedChange,
                    inputPlacaSecund,
                    handleInputPlacaSecundChange,
                    placaSecund,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Nro. Placa Sec.',
                    'small',
                    (!isCreateDialog) || (placaSelected === null)
                )}
            </Grid>
        )
    }

    //FILTROS

    const handleFechaFilter = (date) => {
        setFechaFilter(date)
    }

    const renderFechaFilter = () => {
        return (
            <Grid item xs={12} sm={2} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-filter"
                    label="Fecha"
                    selectedValue={fechaFilter}
                    onChange={handleFechaFilter}>
                </DateSelector>
            </Grid>
        )
    }

    const changeStateOnlyIfNumber = (newValue, stateFunction) => {
        if (/^\d*$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const renderDestinoFilter = () => {
        return (
            <Grid item xs={12} sm={4.75} md={2.625} lg={2.625} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="rucDestino-textfield"
                    label="RUC Destinatario"
                    variant="outlined"
                    value={rucDestinatarioFilter}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setRucDestinatarioFilter)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }


    const handleApplyFilters = () => {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const handleClearFilters = () => {
        setFechaFilter('')
        setRucDestinatarioFilter('')
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }


    const renderFilterButtons = () => {
        return (
            <Grid item xs={2.5} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderFechaFilter()}
                        {renderDestinoFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }


    //TABLA MERCADERIA

    const tableColumns = [
        { id: 'columnChecked', align: 'center', label: '', minWidth: 30, format: 'string' },
        { id: 'columnFecha', align: 'center', label: 'Fecha Ingreso', minWidth: 75, format: 'string' },
        { id: 'columnLugarDestino', align: 'center', label: 'Lugar Destino', minWidth: 75, format: 'string' },
        { id: 'columnDestinatario', align: 'center', label: 'Cliente Destinatario', minWidth: 75, format: 'string' },
        { id: 'columnCantidad', align: 'center', label: 'Cantidad', minWidth: 75, format: 'string' },
        { id: 'columnDescripcion', align: 'center', label: 'Descripción', minWidth: 75, format: 'string' },
        { id: 'columnPrecio', align: 'center', label: 'Precio', minWidth: 75, format: 'string' }
    ];

    const handleCheckboxChange = (event, item) => {
        if (event.target.checked) {
            setMercaderiaSelected([...mercaderiaSelected, item]);
        } else {
            setMercaderiaSelected(mercaderiaSelected.filter(selectedItem => selectedItem !== item));
        }
    };


    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(mercaderiaData === null) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (mercaderiaData.map(mercaderia => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${mercaderia.idCaracteristica}`}>
                                            <TableCell align='center'>
                                                <Checkbox disabled={mercaderia.peso==0} color="success" checked={mercaderiaSelected.includes(mercaderia.idCaracteristica)} onChange={(event) => handleCheckboxChange(event, mercaderia.idCaracteristica)} />
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.fechaIngreso}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.destino}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.rucDestinatario} - {mercaderia.razonSocial}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.cantidad}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.nombre}
                                            </TableCell>
                                            <TableCell align='center'>
                                                S/. {mercaderia.total}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }
    const renderPagination = () => {
        return (
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {renderTable()}
                    {renderPagination()}
                </Paper>
            </Grid>
        )
    }


    const renderContent = () => {
        return (
            <DialogContent style={{ padding: '0rem 1.25rem 0.5rem 1.25rem', minWidth: '325px' }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    {renderDividerTitle("Datos Generales")}
                    {renderLugarOrigen()}
                    {renderPlaca()}
                    {renderPlacaSecund()}
                    {/*renderPeso()*/}
                    {renderChofer()}
                    
                    {renderDividerTitle("Selección de Mercadería")}
                    {renderFilters()}
                    {renderData()}
                </Grid>
            </DialogContent>
        )
    }

    const handleCloseButton = () => {
        setDialogOpen(false);
    };

    const buildRequestBody = (isCreate) => {
        var requestBody
        if (isCreate) {
            requestBody = {
                origen: lugarOrigenSelected.id,
                unidadId: placaSelected.id,
                choferId: choferSelected.id,
                //pesoTotal: pesoTotal,
                unidadSecundariaId: placaSecundSelected!=null && placaSecundSelected!=''?placaSecundSelected.id:0,
                caracteristicas: mercaderiaSelected
            }
        } else {
            requestBody = {
                idManifiesto: selectedEnvio.idManifiesto,
                //pesoTotal: pesoTotal,
                caracteristicas: mercaderiaSelected
            }
        }
        return requestBody
    }

    const saveEnvio = async (token, isCreate, reloadData, flagForReloadData, setDialogOpen) => {
        setIsButtonDisabled(true);
        try {
            const response = ((isCreate) ? (await EnviosService.create(token, buildRequestBody(isCreate))) : (await EnviosService.update(token, buildRequestBody(isCreate))))
            if (response) {
                manageAlert('success', ('Envío ' + (isCreate ? 'creado' : 'actualizado') + ' exitosamente'), true)
                reloadData(!flagForReloadData)
                setDialogOpen(false)
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
        setIsButtonDisabled(false);
    }

    const handleSaveButton = () => {

        if (
            lugarOrigenSelected !== null
            && placaSelected != null
            && choferSelected != null
            && mercaderiaSelected.length !== 0
            //&& pesoTotal != null && pesoTotal!==''
        ) {
            saveEnvio(token, isCreateDialog, reloadData, flagForReloadData, setDialogOpen)
        }
        else {
            manageAlert('warning', 'Llenar los campos correctamente', true)
        }
    }

    const renderActionButtons = () => {
        return (
            <DialogActions style={{ padding: '0.5rem 1.25rem 1rem 1.25rem' }}>
                <Button onClick={handleCloseButton} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={handleSaveButton} disabled={isButtonDisabled} variant="contained" >Guardar</Button>
            </DialogActions>
        )
    }

    return (
        <Fragment>
            {renderTitle(title)}
            {renderContent()}
            {renderActionButtons()}
        </Fragment>
    )
}

export default EnviosCreateUpdate