const API_BASE_URL = `${process.env.REACT_APP_BACKEND_FINAN_URL}`;

const manageResponse = async (response) => {
    const statusCode = response.status;

    if (statusCode === 200)
        return await response.json();
    if (statusCode === 201)
        return await response.json();
    else if (statusCode === 204)
        return null;
    else if (statusCode === 400) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else if (statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if (statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la petición`);
    else if (statusCode === 409) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else
        throw new Error(`Error al procesar la petición, contacte al administrador - ${statusCode}`);
}

const CuentasService = {

    getCuentas: async (token, page, tipo, fechaInicio, fechaFin, rucDestinatario, destino) => {
        try {
            const fechaInicioFilter = fechaInicio !== '' ? `&fechaInicio=${fechaInicio}` : ''
            const fechaFinFilter = fechaFin !== '' ? `&fechaFin=${fechaFin}` : ''
            const rucDestFilter = rucDestinatario !== '' ? `&rucDestinatario=${rucDestinatario}` : ''
            const destinoFilter = destino!=null && destino !== '' ? `&destino=${destino}` : ''


            const allfilters = fechaInicioFilter + fechaFinFilter + rucDestFilter + destinoFilter

            const response = await fetch(`${API_BASE_URL}/cuentas/${tipo}?page=${page}${allfilters}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    create: async(token, cuenta) => {
        try {
            const response = await fetch(`${API_BASE_URL}/ingresos/cuentas`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cuenta)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

}

export default CuentasService;