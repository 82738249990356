import { useState, useEffect, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AutoComplete from '../../components/AutoComplete';

import UnidadesService from '../../services/unidades';
import TiposUnidadService from '../../services/tiposunidad';
import MarcasUnidadService from '../../services/marcasunidad';

const UnidadesCreateUpdate = (props) => {
    const [unidadToUpdate, setUnidadToUpdate] = useState(null)

    const [tiposUnidad, setTiposUnidad] = useState([])
    const [tipoUnidadSelected, setTipoUnidadSelected] = useState(null)
    const [inputTipoUnidad, setInputTipoUnidad] = useState('')

    const [marcasUnidad, setMarcasUnidad] = useState([])
    const [marcaUnidadSelected, setMarcaUnidadSelected] = useState(null)
    const [inputMarcaUnidad, setInputMarcaUnidad] = useState('')

    const [placa, setPlaca] = useState('')
    const [capacidad, setCapacidad] = useState('')
    const [alto, setAlto] = useState('')
    const [ancho, setAncho] = useState('')
    const [largo, setLargo] = useState('')

    const [constanciaInscripcion, setConstanciaInscripcion] = useState('')
    const [mtc, setMtc] = useState('')

    const [btnSaveDisabled, setBtnSaveDisabled] = useState(true)

    useEffect(() => {
        loadAllData(props.token, props.isCreateDialog, props.unidadId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadAllData = async (token, isCreateDialog, unidadId) => {
        try {
            let responseData = null
            if (!isCreateDialog) {
                responseData = await UnidadesService.getUnidadById(token, unidadId)
                setUnidadToUpdate(responseData)
            }
            loadDataToForms(token, isCreateDialog, responseData)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const loadDataToForms = (token, isCreateDialog, unidadToUpdate) => {
        findAllTiposUnidad(token, unidadToUpdate)
        findAllMarcasUnidad(token, unidadToUpdate)
        if (!isCreateDialog && unidadToUpdate) {
            setPlaca(unidadToUpdate.placa || '')
            setCapacidad(unidadToUpdate.capacidad || '')
            setAlto(unidadToUpdate.alto || '')
            setAncho(unidadToUpdate.ancho || '')
            setLargo(unidadToUpdate.largo || '')
            setConstanciaInscripcion(unidadToUpdate.constanciaInscripcion || '')
            setMtc(unidadToUpdate.mtc || '')
            setBtnSaveDisabled(false)
        }
        else if (isCreateDialog) {
            setBtnSaveDisabled(false)
        }
    }

    const findAllTiposUnidad = async (token, unidadToUpdate) => {
        try {
            const responseData = await TiposUnidadService.getTiposUnidad(token);
            setTiposUnidad(responseData);
            setTipoUnidadSelected(responseData && unidadToUpdate ? unidadToUpdate.tipoUnidad : null)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const findAllMarcasUnidad = async (token, unidadToUpdate) => {
        try {
            const responseData = await MarcasUnidadService.getMarcasUnidad(token);
            setMarcasUnidad(responseData);
            setMarcaUnidadSelected(responseData && unidadToUpdate ? unidadToUpdate.marcaUnidad : null)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const renderTitle = (title) => {
        return (
            <DialogTitle style={{ padding: '1rem 1.25rem 0.5rem 1.25rem' }}>{title}</DialogTitle>
        )
    }

    const autoCompleteObjectComparator = (option, value) => option.id === value.id/* && option.descripcion === value.descripcion*/;

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }

    function handleOptionLabelDescripcion(objeto) {
        return objeto.descripcion
    }

    const handleTipoUnidadSelectedChange = (event, newValue) => {
        setTipoUnidadSelected(newValue ? newValue : null)
    }

    const handleInputTipoUnidadChange = (event, newValue) => {
        setInputTipoUnidad(newValue)
    }

    const renderTipoUnidadBox = () => {
        return (
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'tipounidad-box',
                    tipoUnidadSelected,
                    handleTipoUnidadSelectedChange,
                    inputTipoUnidad,
                    handleInputTipoUnidadChange,
                    tiposUnidad,
                    handleOptionLabelDescripcion,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Tipo',
                    'small'
                )}
            </Grid>
        )
    }

    const handleMarcaUnidadSelectedChange = (event, newValue) => {
        setMarcaUnidadSelected(newValue ? newValue : null)
    }

    const handleInputMarcaUnidadChange = (event, newValue) => {
        setInputMarcaUnidad(newValue)
    }

    const renderMarcaUnidadBox = () => {
        return (
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    'marcaunidad-box',
                    marcaUnidadSelected,
                    handleMarcaUnidadSelectedChange,
                    inputMarcaUnidad,
                    handleInputMarcaUnidadChange,
                    marcasUnidad,
                    handleOptionLabelDescripcion,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Marca',
                    'small'
                )}
            </Grid>
        )
    }

    const renderPlacaTextField = () => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="placa-textfield"
                    label="Placa"
                    variant="outlined"
                    value={placa}
                    onChange={(event) => setPlaca(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const changeStateOnlyIfNumber = (newValue, stateFunction) => {
        if (/^\d*$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const renderCapacidadTextField = () => {
        return (
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="capacidad-textfield"
                    label="Capacidad (kg)"
                    variant="outlined"
                    value={capacidad}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setCapacidad)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderAltoTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="alto-textfield"
                    label="Alto (cm)"
                    variant="outlined"
                    value={alto}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setAlto)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderAnchoTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="ancho-textfield"
                    label="Ancho (cm)"
                    variant="outlined"
                    value={ancho}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setAncho)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderLargoTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="largo-textfield"
                    label="Largo (cm)"
                    variant="outlined"
                    value={largo}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, setLargo)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderConstanciaInscripcionTextField = () => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="constancia-textfield"
                    label="Constancia inscripción"
                    variant="outlined"
                    value={constanciaInscripcion}
                    onChange={(event) => setConstanciaInscripcion(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderMtcTextField = () => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={6} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="constancia-textfield"
                    label="MTC"
                    variant="outlined"
                    value={mtc}
                    onChange={(event) => setMtc(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderContent = () => {
        return (
            <DialogContent style={{ padding: '0.5rem 1.25rem 0.5rem 1.25rem', minWidth: '325px' }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    {renderTipoUnidadBox()}
                    {renderMarcaUnidadBox()}
                    {renderPlacaTextField()}
                    {renderCapacidadTextField()}
                    {renderAltoTextField()}
                    {renderAnchoTextField()}
                    {renderLargoTextField()}
                    {renderConstanciaInscripcionTextField()}
                    {renderMtcTextField()}
                </Grid>
            </DialogContent>
        )
    }

    const handleCloseButton = () => {
        props.setDialogOpen(false);
    };

    const buildRequestBody = (isCreate) => {
        const requestBody = {
            tipoUnidadId: tipoUnidadSelected.id,
            marcaUnidadId: marcaUnidadSelected.id,
            placa: placa,
            capacidad: capacidad,
            alto: alto,
            ancho: ancho,
            largo: largo,
            constanciaInscripcion: constanciaInscripcion,
            mtc: mtc
        }

        if (!isCreate) {
            requestBody.id = unidadToUpdate.id
        }

        return requestBody
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        props.setSeverityAlert(severityAlert)
        props.setAlertMessage(alertMessage)
        props.setOpenAlert(openAlert)
    }

    const saveUnidad = async (token, isCreate, reloadData, flagForReloadData, setDialogOpen) => {
        try {
            const response = ((isCreate) ? (await UnidadesService.saveUnidad(token, buildRequestBody(isCreate))) : (await UnidadesService.updateUnidad(token, buildRequestBody(isCreate))))
            if (response) {
                manageAlert('success', ('Unidad ' + (isCreate ? 'creada' : 'actualizada') + ' exitosamente'), true)
                reloadData(!flagForReloadData)
                setDialogOpen(false)
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const handleSaveButton = () => {
        if (
            tipoUnidadSelected !== null
            && marcaUnidadSelected !== null
            && placa !== ''
            && capacidad !== ''
            && alto !== ''
            && ancho !== ''
            && largo !== ''
            && constanciaInscripcion !== ''
            // && mtc !== ''
        ) {
            saveUnidad(props.token, props.isCreateDialog, props.reloadData, props.flagForReloadData, props.setDialogOpen)
        }
        else {
            manageAlert('warning', 'Llenar los campos correctamente', true)
        }
    }

    const renderActionButtons = () => {
        return (
            <DialogActions style={{ padding: '0.5rem 1.25rem 1rem 1.25rem' }}>
                <Button onClick={handleCloseButton} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={handleSaveButton} variant="contained" disabled={btnSaveDisabled} >Guardar</Button>
            </DialogActions>
        )
    }

    return (
        <Fragment>
            {renderTitle(props.title)}
            {renderContent()}
            {renderActionButtons()}
        </Fragment>
    )
}

export default UnidadesCreateUpdate