import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Dialog from '@mui/material/Dialog'

import CustomizeSnackBar from '../../components/Snackbar';
import MercaderiaCreateUpdate from './MercaderiaCreateUpdate';
import DeleteObjectDialog from '../../components/DeleteObjectDialog';
import DateSelector from '../../components/DateSelector';
import MercaderiaDetail from './MercaderiaDetail';

import MercaderiaService from '../../services/mercaderia';
import LinearProgress from '@mui/material/LinearProgress';
import ListIcon from '@mui/icons-material/List';

const Mercaderia = (props) => {
    const [mercaderiasData, setMercaderiasData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [fechaFilter, setFechaFilter] = useState('')
    const [docRemitenteFilter, setDocRemitenteFilter] = useState('')
    const [docDestinatarioFilter, setDocIdentidadFilter] = useState('')


    const [activateFilters, setActivateFilters] = useState(false)
    const [openDetailDialog, setOpenDetailDialog] = useState(false);

    const [titleDialog, setTitleDialog] = useState('');
    const [openCreateUpdateDialog, setOpenCreateUpdateDialog] = useState(false);
    const [isCreateDialog, setIsCreateDialog] = useState(true);
    const [isGeneradoGtSelect, setIsGeneradoGtSelect] = useState(0);
    const [selectedMercaderiaId, setSelectedMercaderiaId] = useState(null)

    const [tokenActual, setTokenActual] = useState('');

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const[isDataWaitig, setIsDataWaiting] = useState(true);
    const permisos = JSON.parse(window.localStorage.getItem('permisos')).Mercaderia;

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllClientes(
            loggedDashboardJSON,
            currentPage - 1,
            formatDateForString(fechaFilter),
            docRemitenteFilter,
            docDestinatarioFilter
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, activateFilters]);

    //Cuando fecha en la base de datos es String "dd/mm/aaaa"
    const formatDateForString = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const findAllClientes = async (token, page, fechaFilter, docRemitenteFilter, docDestinatarioFilter) => {
        setIsDataWaiting(true);
        try {

            const responseData = await MercaderiaService.getMercaderias(
                token,
                page,
                fechaFilter,
                docRemitenteFilter,
                docDestinatarioFilter
            );
            setMercaderiasData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
            setIsDataWaiting(false);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });

    const renderDocDestinatarioFilter = () => {
        return (
            <Grid item xs={9.5} sm={9.5} md={3.5} lg={3.5} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="destinatario-filter"
                    label="Nro. Doc. Destinatario"
                    variant="outlined"
                    value={docDestinatarioFilter}
                    onChange={(event) => setDocIdentidadFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    /*
    const changeStateOnlyIfDate = (newValue, stateFunction) => {
        const formatoFecha = /^\d{0,4}(-\d{0,2}){0,2}$/
        if(formatoFecha.test(newValue) || newValue === '') {
            stateFunction(newValue)
        }
    }
    */

    const renderDocRemitenteFilter = () => {
        return (
            <Grid item xs={12} sm={12} md={3.5} lg={3.5} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="nombres-filter"
                    label="Nro. Doc. Remitente"
                    variant="outlined"
                    value={docRemitenteFilter}
                    onChange={(event) => setDocRemitenteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        //if(tipoUnidadSelected !== null || marcaUnidadSelected !== null || placaFilter !== '' || empleadoSelected !== null) {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
        //}
    }

    const handleClearFilters = () => {
        setFechaFilter('')
        setDocIdentidadFilter('')
        setDocRemitenteFilter('')
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={2.5} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const handleFechaFilter = (date) => {
        setFechaFilter(date)
    }

    const renderFechaFilter = () => {
        return (
            <Grid item xs={12} sm={12} md={3.5} lg={3.5} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-filter"
                    label="Fecha"
                    selectedValue={fechaFilter}
                    minDate={"2024-01-01"}
                    maxDate={"2050-01-01"}
                    onChange={handleFechaFilter}>
                </DateSelector>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderFechaFilter()}
                        {renderDocRemitenteFilter()}
                        {renderDocDestinatarioFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'colummnDocIdent', align: 'center', label: 'Fecha', minWidth: 75, format: 'string' },
        { id: 'columnEstado', align: 'center', label: 'Nro. Doc. Rem.', minWidth: 75, format: 'string' },
        { id: 'columnNombres', align: 'center', label: 'Remitente', minWidth: 75, format: 'string' },
        { id: 'columnCelular', align: 'center', label: 'Nro. Doc. Dest.', minWidth: 75, format: 'string' },
        { id: 'columnAcciones', align: 'center', label: 'Destinatario', minWidth: 75, format: 'string' },
        { id: 'columnAcciones', align: 'center', label: 'Opciones', minWidth: 75, format: 'string' }
    ];

    const handleDetailTableButton = (mercaderiaId) => {
        setSelectedMercaderiaId(mercaderiaId)
        setOpenDetailDialog(true)
    }

    const handleEditTableButton = (mercaderiaId, isGeneradoGt) => {
        setSelectedMercaderiaId(mercaderiaId)
        setTitleDialog('Actualizar Ingreso')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(false)
        setIsGeneradoGtSelect(isGeneradoGt);
    }

    const handleDeleteTableButton = (mercaderiaId) => {
        setSelectedMercaderiaId(mercaderiaId)
        setOpenDeleteDialog(true)
    }

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {isDataWaitig ? null : renderPagination()}
                </Paper>
            </Grid>
        )
    }

    const renderActionTableButtons = (mercaderia) => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
                <ThemeProvider theme={theme}>
                    <Grid item xs={4} sm={4} md={4} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <IconButton aria-label="edit" color="info" onClick={() => handleDetailTableButton(mercaderia.idMercaderia)}><ListIcon fontSize="medium" /></IconButton>
                    </Grid>
                    {
                        permisos.includes(3) ?
                            <Grid item xs={4} sm={4} md={4} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton  aria-label="edit" color="warning" onClick={() => handleEditTableButton(mercaderia.idMercaderia, mercaderia.isGeneradoGt)}><EditIcon fontSize="medium" /></IconButton>
                            </Grid> : null
                    }
                    {
                        permisos.includes(4) ?
                            <Grid item xs={4} sm={4} md={4} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton disabled={mercaderia.isGeneradoGt != 0} aria-label="delete" color="error" onClick={() => handleDeleteTableButton(mercaderia.idMercaderia)}><DeleteIcon fontSize="medium" /></IconButton>
                            </Grid> : null
                    }

                </ThemeProvider>
            </Grid>
        )
    }

    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        {isDataWaitig ? <LinearProgress color="success" /> : null}
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {(mercaderiasData === null || isDataWaitig) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (mercaderiasData.map(mercaderia => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${mercaderia.idMercaderia}`}>
                                            <TableCell align='center'>
                                                {mercaderia.fechaIngreso}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.remitente.ruc || "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.remitente.razonSocial || "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.destinatario.ruc || "---"}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {mercaderia.destinatario.razonSocial || "---"}
                                            </TableCell>
                                            <TableCell key="options" align="center">
                                                {renderActionTableButtons(mercaderia)}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                {isDataWaitig ? <LinearProgress color="success" /> : null}
                <Table>
                    {(mercaderiasData === null || isDataWaitig) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (mercaderiasData.map(cliente => (
                            <TableBody key={`${cliente.idMercaderia}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Fecha:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cliente.fechaIngreso}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Nro. Doc. Rem:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {cliente.remitente.ruc}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Remitente:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cliente.remitente.razonSocial || "---"}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Nro. Doc. Dest:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {cliente.destinatario.ruc}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 115px' }}>Destinatario:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cliente.destinatario.razonSocial || "---"}</div>
                                        </div>
                                        {renderActionTableButtons(cliente)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const handleAddButton = () => {
        setTitleDialog('Registrar Ingreso')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(true)
        setIsGeneradoGtSelect(0)
    }

    const renderAddButton = () => {
        return (
            <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: '0.6rem 0 0 0' }}>
                <Grid item>
                    <Button color="success" onClick={handleAddButton} variant="contained">Registrar Ingreso</Button>
                </Grid>
            </Grid>
        )
    }

    
    const renderDetailsDialog = () => {
        return (
            <Dialog fullWidth maxWidth={props.isSmUp ? 'md' : 'xs'} open={openDetailDialog} onClose={() => setOpenDetailDialog(false)}>
                <MercaderiaDetail
                    token={tokenActual}
                    mercaderiaId={selectedMercaderiaId}
                    setDialogOpen={setOpenDetailDialog}
                    isSmUp={props.isSmUp}
                    manageAlert={manageAlert}
                >
                </MercaderiaDetail>
            </Dialog>
        )
    }


    const renderCreateUpdateDialog = () => {
        return (
            <Dialog fullWidth maxWidth={props.isSmUp ? 'md' : 'xs'} open={openCreateUpdateDialog} onClose={() => setOpenCreateUpdateDialog(false)}>
                <MercaderiaCreateUpdate
                    title={titleDialog}
                    token={tokenActual}
                    isCreateDialog={isCreateDialog}
                    mercaderiaId={selectedMercaderiaId}
                    setDialogOpen={setOpenCreateUpdateDialog}
                    reloadData={setActivateFilters}
                    flagForReloadData={activateFilters}
                    isSmUp={props.isSmUp}
                    manageAlert={manageAlert}
                    isGeneradoGtSelect={isGeneradoGtSelect}
                >
                </MercaderiaCreateUpdate>
            </Dialog>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    const handleDelete = async () => {
        try {
            await MercaderiaService.deleteById(tokenActual, selectedMercaderiaId);
            manageAlert('success', 'Mercaderia con id ' + selectedMercaderiaId + ' eliminada exitosamente', true)
            handleClearFilters()
            setOpenDeleteDialog(false)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const renderDeleteObject = (mercaderiaId) => {
        return (
            <DeleteObjectDialog
                setOpen={setOpenDeleteDialog}
                deleteFunction={handleDelete}
                open={openDeleteDialog}
                title={'Eliminar Mercadería'}
                contentMessage={'¿Está seguro que desea dar de baja al registro de mercadería?'}
            />
        )
    }

    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderData()}
            {permisos.includes(2) ? renderAddButton() : null}
            {renderCreateUpdateDialog()}
            {renderDetailsDialog()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
            {renderDeleteObject(selectedMercaderiaId)}
        </Grid>
    );
};

export default Mercaderia;