import React, { useState, useEffect, Fragment } from "react";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WorkIcon from '@mui/icons-material/Work';
import ModuloServices from "../services/modulos";

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function SideBar(props) {
  const { ...other } = props;

  const [openElement, setOpenElement] = useState(null);

  const handleClickDesplegableElement = (supermodulo) => {
    setOpenElement((prevOpenElement) =>
      prevOpenElement === supermodulo ? null : supermodulo
    );
  };

  const tokenTemporal = "eyJhbGciOiJIUzI1NiJ9.eyJzdWJqZWN0IjoiY2xhcmtpcnZpbmcyMiIsImF1dGhvcml0aWVzIjoiW3tcImF1dGhvcml0eVwiOlwiUk9MRV9VU1VBUklPXCJ9XSIsImlhdCI6MTcxMDU0MTg2OCwiZXhwIjoxNzExNzYxNDY4fQ.K7_4urLSGVtnSfJoPN3QF_Y9LWoFlJzCQl0eDGyur2I";

  const [modulosResponse, setModulosResponse] = useState(null);
  const [error, setError] = useState(null);

  const findAllModulos = async (token) => {
    try {
      const responseData = await ModuloServices.getModulosByUsuario(token);

      const outputJson = {};
      for (const module in responseData) {
        responseData[module].forEach(item => {
          const actionIds = item.acciones.map(action => action.id).join(',');
          // Remove spaces from the key
          const key = item.nombre.replace(/\s+/g, '');
          outputJson[key] = actionIds;
        });
      }
      window.localStorage.setItem("permisos", JSON.stringify(outputJson))

      setModulosResponse(responseData);
    }
    catch (error) {
      setError(error.message);
    }
  };

  useEffect(
    () => {
      const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
      findAllModulos(loggedDashboardJSON);
    }, []
  );

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 15, color: '#fff' }} style={{ justifyContent: 'center' }}>
          <img src="/logo2.png" style={{ maxWidth: "170px" }} />
        </ListItem>
        <Link key="home" to="/" style={{ textDecoration: 'none' }}>
          <ListItem sx={{ ...item, ...itemCategory }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Inicio</ListItemText>
          </ListItem>
        </Link>

        {error && alert(error)}
        {modulosResponse && Object.keys(modulosResponse).map((supermodulo) => (
          <Box sx={{ bgcolor: '#047A04' }}>
            <ListItem disablePadding>
              <ListItemButton sx={item} onClick={() => handleClickDesplegableElement(supermodulo)}>
                <ListItemIcon>
                  {
                    supermodulo == 'Gestion de Almacen' ? <LocalShippingIcon /> : null
                  }
                  {
                    supermodulo == 'Seguridad' ? <AdminPanelSettingsIcon /> : null
                  }
                  {
                    supermodulo == 'Admin. Financiera' ? <MonetizationOnIcon /> : null
                  }
                  {
                    supermodulo == 'Admin. Comercial' ? <WorkIcon /> : null
                  }
                </ListItemIcon>
                <ListItemText>{supermodulo}</ListItemText>
                {openElement === supermodulo ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>

            <Collapse in={openElement === supermodulo} timeout="auto" unmountOnExit>
              {modulosResponse[supermodulo].map((modulo) => (
                <>
                  <Link key={modulo.id} to={`/dashboard/modulos/${modulo.nombre}`} style={{ textDecoration: 'none', color: 'white' }}>
                    <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText>{modulo.nombre}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Divider />
                </>
              ))}
            </Collapse>
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}

      </List>
    </Drawer>
  );
}