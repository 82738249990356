import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CustomizeSnackBar from '../../components/Snackbar';
import CuentasService from '../../services/cuentas';
import GuiaService from '../../services/guia';
import DateSelector from '../../components/DateSelector';
import LinearProgress from '@mui/material/LinearProgress';
import AutoComplete from '../../components/AutoComplete';
import CuentasUpdate from './CuentasUpdate';
import Dialog from '@mui/material/Dialog'

const Cuentas = (props) => {
    const tipoCliente = [
        {
            id: "SI",
            nombre: "CREDITO"
        },
        {
            id: "NO",
            nombre: "CONTADO"
        }
    ]


    const [cuentasData, setCuentasData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    //FILTROS
    const [tipos, setTipo] = useState(tipoCliente)
    const [tipoSelected, setTipoSelected] = useState({ id: 'SI', nombre: 'CREDITO' });
    const [inputTipo, setInputTipo] = useState('');

    const [fechaInicioFilter, setFechaInicioFilter] = useState('')
    const [fechaFinFilter, setFechaFinFilter] = useState('')
    const [disabledFecha, setDisabledFecha] = useState(true);

    const [destinos, setDestinos] = useState([])
    const [destinoSelected, setDestinoSelected] = useState(null);
    const [inputDestino, setInputDestino] = useState('');
    const [docClienteFilter, setDocClienteFilter] = useState('')

    const [activateFilters, setActivateFilters] = useState(false)

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')
    const [tokenActual, setTokenActual] = useState('');

    const [isDataWaitig, setIsDataWaiting] = useState(true);
    const [selectedCuenta, setSelectedCuenta] = useState(null)
    const [openCuentasDialog, setOpenCuentasDialog] = useState(false)

    const permisos = JSON.parse(window.localStorage.getItem('permisos')).Cuentas;


    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });


    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllDestino(loggedDashboardJSON)
    }, [])

    const findAllDestino = async (token) => {
        try {
            const responseData = await GuiaService.getDestinosCuentas(token);
            setDestinos(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllCuentas(
            loggedDashboardJSON,
            currentPage - 1,
            tipoSelected != null ? tipoSelected.id : null,
            formatDateForString(fechaInicioFilter),
            formatDateForString(fechaFinFilter),
            docClienteFilter,
            destinoSelected != null ? destinoSelected.id : null
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
    }, [currentPage, activateFilters]);

    const formatDateForString = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const findAllCuentas = async (token, page, tipo, fechaInicio, fechaFin, rucDestinatario, destino) => {
        setIsDataWaiting(true);
        try {
            const responseData = await CuentasService.getCuentas(token, page, tipo, fechaInicio, fechaFin, rucDestinatario, destino);
            setCuentasData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
            setIsDataWaiting(false);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    // FILTRO TIPO

    const renderAutocompleteFilter = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }
    const handleTipoSelectedChange = (event, newValue) => {
        setTipoSelected(newValue ? newValue : null)
    }

    const handleInputTipoChange = (event, newValue) => {
        setInputTipo(newValue)
    }

    function handleTipoLabelNombre(objeto) {
        return objeto.nombre
    }
    const autoCompleteObjectComparator = (option, value) => option.id === value.id

    const renderTipoFilter = () => {
        return (
            <Grid item xs={12} sm={2} md={2} lg={2} style={{ paddingBottom: '0.25rem' }}>
                {renderAutocompleteFilter(
                    'tipo-filter',
                    tipoSelected,
                    handleTipoSelectedChange,
                    inputTipo,
                    handleInputTipoChange,
                    tipos,
                    handleTipoLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    100,
                    'Tipo Cliente',
                    'small'
                )}
            </Grid>
        )
    }

    // FILTRO FECHA INICIO
    const handleFechaInicioFilter = (date) => {
        setFechaInicioFilter(date)
        setDisabledFecha(false)
    }

    const renderFechaInicioFilter = () => {
        return (
            <Grid item xs={6} sm={2} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-inicio-filter"
                    label="Fecha Inicio"
                    selectedValue={fechaInicioFilter}
                    onChange={handleFechaInicioFilter}>
                </DateSelector>
            </Grid>
        )
    }

    // FILTRO FECHA FIN
    const handleFechaFinFilter = (date) => {
        setFechaFinFilter(date)
    }

    const renderFechaFinFilter = () => {
        return (
            <Grid item xs={6} sm={2} md={2} lg={2} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <DateSelector
                    id="fecha-fin-filter"
                    label="Fecha Fin"
                    selectedValue={fechaFinFilter}
                    disabled={disabledFecha}
                    onChange={handleFechaFinFilter}>
                </DateSelector>
            </Grid>
        )
    }

    // FILTRO DESTINO
    const handleDestinoSelectedChange = (event, newValue) => {
        setDestinoSelected(newValue ? newValue : null)
    }

    const handleInputDestinoChange = (event, newValue) => {
        setInputDestino(newValue)
    }

    function handleDestinoLabelNombre(objeto) {
        return objeto.nombre
    }

    const renderDestinoFilter = () => {
        return (
            <Grid item xs={12} sm={4.75} md={2.625} lg={2.625} style={{ paddingBottom: '0.25rem' }}>
                {renderAutocompleteFilter(
                    'destino-filter',
                    destinoSelected,
                    handleDestinoSelectedChange,
                    inputDestino,
                    handleInputDestinoChange,
                    destinos,
                    handleDestinoLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Destino',
                    'small'
                )}
            </Grid>
        )
    }

    //FILTRO RUC CLIENTE

    const renderClienteFilter = () => {
        return (
            <Grid item xs={12} sm={3} md={3} lg={3} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="cliente-filter"
                    label="Nro. Doc. Cliente"
                    variant="outlined"
                    value={docClienteFilter}
                    onChange={(event) => setDocClienteFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const handleClearFilters = () => {
        setFechaInicioFilter('')
        setFechaFinFilter('')
        setDocClienteFilter('')
        setDestinoSelected(null)
        setDisabledFecha(true)
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={2.5} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderTipoFilter()}
                        {renderFechaInicioFilter()}
                        {renderFechaFinFilter()}
                        {renderDestinoFilter()}
                        {renderClienteFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'colummnFechaEmision', align: 'center', label: 'Fecha Emisión', minWidth: 75, format: 'string' },
        { id: 'columnDestino', align: 'center', label: 'Destino', minWidth: 75, format: 'string' },
        { id: 'columnRucCliente', align: 'center', label: 'R.U.C. Cliente', minWidth: 75, format: 'string' },
        { id: 'columnRazonCliente', align: 'center', label: 'Razon Social Cliente', minWidth: 75, format: 'string' },
        { id: 'columnGuias', align: 'center', label: 'Guias Transp.', minWidth: 75, format: 'string' },
        { id: 'columnMonto', align: 'center', label: 'Monto', minWidth: 75, format: 'string' },
        { id: 'columnOpciones', align: 'center', label: 'Opciones', minWidth: 75, format: 'string' }
    ];

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {isDataWaitig ? null : renderPagination()}
                </Paper>
            </Grid>
        )
    }


    const handleClickEdit = (cuenta) => {
        setSelectedCuenta(cuenta)
        setOpenCuentasDialog(true)
    };


    const renderActionTableButtons = (cuenta) => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
                <ThemeProvider theme={theme}>
                    {
                        permisos.includes(2) || permisos.includes(3) ?
                            <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton aria-label="edit" color="refresh" onClick={() => handleClickEdit(cuenta)}>
                                    <PriceCheckIcon fontSize="medium" />
                                </IconButton>
                            </Grid> : null
                    }
                </ThemeProvider>
            </Grid>
        )
    }

    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        {isDataWaitig ? <LinearProgress color="success" /> : null}
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(cuentasData === null || isDataWaitig) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (cuentasData.map(cuenta => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${cuenta.idGuiaTransportista}`}>
                                            <TableCell align='center'>
                                                {cuenta.fechaRegistro}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {cuenta.destino}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {cuenta.rucDestinatario}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {cuenta.razonSocial}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {cuenta.serie}-{cuenta.codigo}
                                            </TableCell>
                                            <TableCell align='center'>
                                                S/.{cuenta.precioTotal}
                                            </TableCell>
                                            <TableCell key="options" align="center">
                                                {renderActionTableButtons(cuenta)}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };


    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                {isDataWaitig ? <LinearProgress color="success" /> : null}
                <Table>
                    {(cuentasData === null || isDataWaitig) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (cuentasData.map(cuenta => (
                            <TableBody key={`${cuenta.idGuiaTransportista}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Fecha Emisión:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cuenta.fechaRegistro}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Destino:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cuenta.destino}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>RUC Cliente:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cuenta.rucDestinatario}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Razon Social Cliente:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{cuenta.razonSocial}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Guias Transp.:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> {cuenta.serie}-{cuenta.codigo}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 105px' }}>Monto:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}> S/.{cuenta.precioTotal} </div>
                                        </div>
                                        {renderActionTableButtons(cuenta)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    const renderCreateUpdateDialog = () => {
        return (
            <Dialog fullWidth maxWidth={props.isSmUp ? 'md' : 'xs'} open={openCuentasDialog} onClose={() => setOpenCuentasDialog(false)}>
                <CuentasUpdate
                    token={tokenActual}
                    selectedCuenta={selectedCuenta}
                    setDialogOpen={setOpenCuentasDialog}
                    reloadData={setActivateFilters}
                    flagForReloadData={activateFilters}
                    manageAlert={manageAlert}
                >
                </CuentasUpdate>
            </Dialog>
        )
    }

    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderData()}
            {renderCreateUpdateDialog()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
        </Grid>
    );
};

export default Cuentas;