import { useState, useEffect, Fragment } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CuentasService from '../../services/cuentas';


const CuentasUpdate = ({
    token,
    selectedCuenta,
    setDialogOpen,
    reloadData,
    flagForReloadData,
    manageAlert,
}) => {

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [cobrador, setCobrador] = useState('')
    const [aumento, setAumento] = useState('')
    const [descuento, setDescuento] = useState('')
    const [total, setTotal] = useState(selectedCuenta.precioTotal)

    useEffect(() => {
        const aumentoInterno = parseFloat(aumento) || 0;
        const descuentoInterno = parseFloat(descuento) || 0;
        const totalInterno = (selectedCuenta.precioTotal - descuentoInterno  + aumentoInterno).toFixed(2);
        setTotal(totalInterno)
    }, [aumento, descuento]);

    const renderTitle = () => {
        return (
            <DialogTitle style={{ padding: '1rem 1.25rem 0.5rem 1.25rem' }}>Actualizar Cuentas</DialogTitle>
        )
    }

    const renderCobrador = () => {
        return (
            <Grid item xs={12} sm={3} md={3} lg={3} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="cobrador-textfield"
                    label="Cobrador"
                    variant="outlined"
                    value={cobrador}
                    onChange={(event) => setCobrador(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const changeStateOnlyIfDecimalNumber = (newValue, stateFunction) => {
        if (/^\d{0,5}(\.\d{0,2})?$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    
    const handleAumentoBlur = (value) => {
        const formattedValue = parseFloat(value === '' ? 0 : value).toFixed(2);
        setAumento(formattedValue)
    };

    const renderAumento = () => {
        return (
            <Grid item xs={12} sm={3} md={3} lg={3} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="aumento-textfield"
                    label="Aumento"
                    variant="outlined"
                    value={aumento}
                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, setAumento)}
                    onBlur={(event) => handleAumentoBlur(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    
    const handleDescuentoBlur = (value) => {
        const formattedValue = parseFloat(value === '' ? 0 : value).toFixed(2);
        setDescuento(formattedValue)
    };

    const renderDescuento = () => {
        return (
            <Grid item xs={12} sm={3} md={3} lg={3} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="descuento-textfield"
                    label="Descuento"
                    variant="outlined"
                    value={descuento}
                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, setDescuento)}
                    onBlur={(event) => handleDescuentoBlur(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderTotal = () => {
        return (
            <Grid item xs={12} sm={3} md={3} lg={3} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    autoComplete="off"
                    id="total-textfield"
                    label="Total"
                    variant="outlined"
                    value={total}
                    size='small'
                    
                    fullWidth>
                </TextField>
            </Grid>
        )
    }
    
    const renderContent = () => {
        return (
            <DialogContent style={{ padding: '1rem 1.25rem 0.5rem 1.25rem', minWidth: '325px' }}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                    {renderCobrador()}
                    {renderAumento()}
                    {renderDescuento()}
                    {renderTotal()}
                </Grid>
            </DialogContent>
        )
    }

    const handleCloseButton = () => {
        setDialogOpen(false);
    };

    const buildRequestBody = () => {
        const requestBody = {
			idGuiaTransportista: selectedCuenta.idGuiaTransportista,
			razonSocial: selectedCuenta.razonSocial,
			destino: selectedCuenta.destino,
			montoActual: total,
            cobrador: cobrador
		}
        return requestBody
    }

    const saveCuenta = async (token, reloadData, flagForReloadData, setDialogOpen) => {
        setIsButtonDisabled(true);
        try {
            const response = await CuentasService.create(token, buildRequestBody())
            if (response) {
                manageAlert('success', ('Envío creado exitosamente'), true)
                reloadData(!flagForReloadData)
                setDialogOpen(false)
            }
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
        setIsButtonDisabled(false);
    }

    const handleSaveButton = () => {
        if (cobrador !=''
        ) {
            saveCuenta(token, reloadData, flagForReloadData, setDialogOpen)
        }
        else {
            manageAlert('warning', 'Llenar los campos correctamente', true)
        }
    }

    const renderActionButtons = () => {
        return (
            <DialogActions style={{ padding: '0.5rem 1.25rem 1rem 1.25rem' }}>
                <Button onClick={handleCloseButton} variant="outlined" color="error">Cancelar</Button>
                <Button onClick={handleSaveButton} disabled={isButtonDisabled} variant="contained" >Guardar</Button>
            </DialogActions>
        )
    }

    return (
        <Fragment>
            {renderTitle()}
            {renderContent()}
            {renderActionButtons()}
        </Fragment>
    )
}

export default CuentasUpdate