import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import AutoComplete from '../../components/AutoComplete';

const MercaderiaCaracteristica = ({
    caracteristica,
    onCaracteristicaChange,
    tiposMercaderia,
    tiposMoneda,
    indexCaracteristica,
    onDelete,
    unidadMedida
    //direccionCompletedState,
}) => {
    const [caracteristicaState, setCaracteristicaState] = useState(caracteristica)

    /*useEffect(() => {
        // Formatear los valores iniciales con dos decimales
        setCaracteristicaState(prevState => ({
            ...prevState,
            precioUnitario: parseFloat(caracteristica.precioUnitario).toFixed(2),
            total: parseFloat(caracteristica.total).toFixed(2),
        }));
    }, [caracteristica]);*/

    useEffect(() => {
        // Recalcular el total cuando cambian precioUnitario o cantidad
        const precioUnitario = parseFloat(caracteristicaState.precioUnitario) || 0
        const cantidad = parseFloat(caracteristicaState.cantidad) || 0
        const peso = parseFloat(caracteristicaState.peso) || 0
        let total = 0

        //Volumen id = 1
        if(caracteristicaState.tipoMercaderia && caracteristicaState.tipoMercaderia.id === 1) {
            total = (precioUnitario * cantidad).toFixed(2)
        }

        //Peso id = 3
        if(caracteristicaState.tipoMercaderia && caracteristicaState.tipoMercaderia.id === 3) {
            total = (precioUnitario * peso).toFixed(2)
        }
        
        handleFieldChange({ total });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caracteristicaState.tipoMercaderia, caracteristicaState.precioUnitario, caracteristicaState.cantidad, caracteristicaState.peso]);

    const [inputTipoMercaderia, setInputTipoMercaderia] = useState('')

    const [inputTipoMoneda, setInputTipoMoneda] = useState('')

    const [inputUnidadMedida, setInputUnidadMedida] = useState('')

    const handleFieldChange = (values) => {
        setCaracteristicaState(prevState => ({
            ...prevState,
            ...values
        }))

        onCaracteristicaChange({
            ...caracteristicaState,
            ...values
        })
    }

    const handleDelete = () => {
        onDelete(caracteristicaState.id);
    }

    const autoCompleteObjectComparatorById = (option, value) => option.id === value.id/* && option.descripcion === value.descripcion*/

    function handleOptionLabelNombre (objeto) {
        return objeto.nombre
    }

    const renderAutocomplete = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size, disabled) => {
        return (
            <AutoComplete
                id = {id}
                value = {value || null}
                onChange = {onChange}
                inputValue = {inputValue || ''}
                onInputChange = {onInputChange}
                options = {options}
                getOptionLabel = {getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width = {width}
                label = {label}
                size = {size}
                disabled={disabled}
            />
        )
    }

    const handleTipoMercaderiaSelectedChange = (event, newValue) => {
        handleFieldChange({
            tipoMercaderia: newValue ? newValue : null
        })
    }

    const handleInputTipoMercaderiaChange = (event, newValue) => {
        setInputTipoMercaderia(newValue)
    }

    const renderTipoMercaderia = () => {
        return (
            <Grid item xs={12} sm={3} md={3} lg={3} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    `caracteristica${caracteristicaState.id}-tipoMercaderiaBox`,
                    caracteristicaState.tipoMercaderia,
                    handleTipoMercaderiaSelectedChange,
                    inputTipoMercaderia,
                    handleInputTipoMercaderiaChange,
                    tiposMercaderia,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Tipo Mercadería',
                    'small',
                    caracteristicaState.estado!=1
                )}
            </Grid>
        )
    }

    const handleDescripcionChange = (newValue) => {
        handleFieldChange({
            nombre: newValue
        })
    }

    const renderDescripcionTextField = () => {
        return (
            <Grid item xs={12} sm={9} md={9} lg={9} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id={`caracteristica${caracteristicaState.id}-descripcionTextField`}
                    label="Descripción"
                    variant="outlined"
                    value={caracteristicaState.nombre}
                    onChange={(event) => handleDescripcionChange(event.target.value)}
                    size='small'
                    fullWidth
                    disabled={caracteristicaState.estado!=1}
                    inputProps={{
                        maxlength: 50
                    }}
                >
                </TextField>
            </Grid>
        )
    }

    const handleTipoMonedaSelectedChange = (event, newValue) => {
        handleFieldChange({
            tipoMoneda: newValue ? newValue : null
        })
    }

    const handleInputTipoMonedaChange = (event, newValue) => {
        setInputTipoMoneda(newValue)
    }

    const renderTipoMoneda = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    `caracteristica${caracteristicaState.id}-tipoMonedaBox`,
                    caracteristicaState.tipoMoneda,
                    handleTipoMonedaSelectedChange,
                    inputTipoMoneda,
                    handleInputTipoMonedaChange,
                    tiposMoneda,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Tipo Moneda',
                    'small',
                    caracteristicaState.estado!=1
                )}
            </Grid>
        )
    }

    const handleCantidadChange = (newValue) => {
        handleFieldChange({
            cantidad: newValue
        })
    }

    const changeStateOnlyIfNumber = (newValue, stateFunction) => {
        if(/^\d*$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const renderCantidadTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id={`caracteristica${caracteristicaState.id}-cantidadTextField`}
                    label="Cantidad"
                    variant="outlined"
                    value={caracteristicaState.cantidad}
                    onChange={(event) => changeStateOnlyIfNumber(event.target.value, handleCantidadChange)}
                    size='small'
                    fullWidth
                    disabled={caracteristicaState.estado!=1}
                    inputProps={{
                        maxlength: 23
                    }}
                >
                </TextField>
            </Grid>
        )
    }

    const handlePrecioUnitarioChange = (newValue) => {
        handleFieldChange({
            precioUnitario: newValue
        })
    }

    const handlePrecioUnitarioBlur = (value) => {
        const formattedValue = parseFloat(value === '' ? 0 : value).toFixed(2);
        handleFieldChange({
            precioUnitario: formattedValue
        });
    };

    const changeStateOnlyIfDecimalNumber = (newValue, stateFunction) => {
        if(/^\d{0,5}(\.\d{0,2})?$/.test(newValue)) {
            stateFunction(newValue)
        }
    }

    const renderPrecioUnitarioTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id={`caracteristica${caracteristicaState.id}-precioUnitarioTextField`}
                    label="Precio Unitario"
                    variant="outlined"
                    value={caracteristicaState.precioUnitario}
                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, handlePrecioUnitarioChange)}
                    onBlur={(event) => handlePrecioUnitarioBlur(event.target.value)}
                    size='small'
                    fullWidth
                    disabled={caracteristicaState.estado!=1}
                    inputProps={{
                        maxlength: 100
                    }}
                >
                </TextField>
            </Grid>
        )
    }

    const handleUnidadMedidaSelectedChange = (event, newValue) => {
        handleFieldChange({
            unidadMedida: newValue ? newValue : null
        })
    }

    const handleInputUnidadMedidaChange = (event, newValue) => {
        setInputUnidadMedida(newValue)
    }

    const renderUnidadMedida = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                {renderAutocomplete(
                    `caracteristica${caracteristicaState.id}-unidadMedidaBox`,
                    caracteristicaState.unidadMedida,
                    handleUnidadMedidaSelectedChange,
                    inputUnidadMedida,
                    handleInputUnidadMedidaChange,
                    unidadMedida,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparatorById,
                    true,
                    300,
                    'Unidad medida',
                    'small',
                    caracteristicaState.estado!=1
                )}
            </Grid>
        )
    }

    const handlePesoChange = (newValue) => {
        handleFieldChange({
            peso: newValue
        })
    }

    const handlePesoBlur = (value) => {
        const formattedValue = parseFloat(value === '' ? 0 : value).toFixed(2);
        handleFieldChange({
            peso: formattedValue
        });
    };

    const renderPesoTextField = () => {
        return (
            <Grid item xs={6} sm={4} md={4} lg={4} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id={`caracteristica${caracteristicaState.id}-pesoTextField`}
                    label="Peso"
                    variant="outlined"
                    value={caracteristicaState.peso}
                    onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, handlePesoChange)}
                    onBlur={(event) => handlePesoBlur(event.target.value)}
                    size='small'
                    fullWidth
                    disabled={caracteristicaState.estado!=1}
                    inputProps={{
                        maxlength: 100
                    }}
                >
                </TextField>
            </Grid>
        )
    }

    const renderPrecioTotalTextField = () => {
        return (
            <Grid item xs={4.5} sm={2.85} md={3} lg={3} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <TextField
                    id={`caracteristica${caracteristicaState.id}-precioTotalTextField`}
                    label="Precio Total"
                    variant="outlined"
                    value={caracteristicaState.total}
                    //onChange={(event) => changeStateOnlyIfDecimalNumber(event.target.value, handlePrecioUnitarioChange)}
                    size='small'
                    disabled
                    fullWidth
                >
                </TextField>
            </Grid>
        )
    }

    return (
        <Grid style={{padding: '0px', margin: '0px'}} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <Divider textAlign="left">
                    Mercaderia {indexCaracteristica}
                </Divider>
            </Grid>
            {renderTipoMercaderia()}
            {renderDescripcionTextField()}
            {renderTipoMoneda()}
            {renderCantidadTextField()}
            {renderPrecioUnitarioTextField()}
            {renderUnidadMedida()}
            {renderPesoTextField()}
            {renderPrecioTotalTextField()}
            <Grid item xs={1.5} sm={1.15} md={1} lg={1} style={{ /*paddingBottom: '0.25rem'*/ }}>
                <IconButton disabled={caracteristicaState.estado!=1} aria-label="add-address" size="medium" color="error" onClick={handleDelete}>
                    <RemoveCircleOutlineIcon fontSize="medium" />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default MercaderiaCaracteristica