import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
//import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Dialog from '@mui/material/Dialog'
import Checkbox from '@mui/material/Checkbox';

import AutoComplete from '../../components/AutoComplete';
import EmpleadosCreateUpdate from './EmpleadosCreateUpdate';
import CustomizeSnackBar from '../../components/Snackbar';
//import DeleteObjectDialog from '../../components/DeleteObjectDialog';

import EmpleadosService from '../../services/empleados';
import AreasService from '../../services/areas';

const Empleados = (props) => {
    const [empleadosData, setEmpleadosData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [docIdentidadFilter, setDocIdentidadFilter] = useState('')
    const [nombresFilter, setNombresFilter] = useState('')
    const [apellidosFilter, setApellidosFilter] = useState('')

    const [areas, setAreas] = useState([])
    const [areaSelected, setAreaSelected] = useState(null);
    const [inputArea, setInputArea] = useState('');

    const [activateFilters, setActivateFilters] = useState(false)

    const [titleDialog, setTitleDialog] = useState('');
    const [openCreateUpdateDialog, setOpenCreateUpdateDialog] = useState(false);
    const [isCreateDialog, setIsCreateDialog] = useState(true);
    const [selectedEmpleadoId, setSelectedEmpleadoId] = useState(null)
    //const [selectedEmpleadoDni, setSelectedEmpleadoDni] = useState('')

    const [tokenActual, setTokenActual] = useState('');

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    //const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const permisos = JSON.parse(window.localStorage.getItem('permisos')).Empleados;
    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllEmpleados(
            loggedDashboardJSON,
            currentPage - 1,
            docIdentidadFilter,
            nombresFilter,
            apellidosFilter,
            areaSelected ? areaSelected.id : null
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, activateFilters]);

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        findAllAreas(loggedDashboardJSON)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findAllEmpleados = async (token, page, docIdentidad, nombres, apellidos, areaId) => {
        try {
            const responseData = await EmpleadosService.getEmpleados(token, page, docIdentidad, nombres, apellidos, areaId);
            setEmpleadosData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const findAllAreas = async (token) => {
        try {
            const responseData = await AreasService.getAreas(token);
            setAreas(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });

    const renderNombresFilter = () => {
        return (
            <Grid item xs={6} sm={6} md={2.625} lg={2.625} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="nombres-filter"
                    label="Nombres"
                    variant="outlined"
                    value={nombresFilter}
                    onChange={(event) => setNombresFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderApellidosFilter = () => {
        return (
            <Grid item xs={6} sm={6} md={2.625} lg={2.625} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="apellidos-filter"
                    label="Apellidos"
                    variant="outlined"
                    value={apellidosFilter}
                    onChange={(event) => setApellidosFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const renderDocIdentidadFilter = () => {
        return (
            <Grid item xs={4.5} sm={4.75} md={2.625} lg={2.625} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    autoComplete="off"
                    id="placa-filter"
                    label="Doc. Ident."
                    variant="outlined"
                    value={docIdentidadFilter}
                    onChange={(event) => setDocIdentidadFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    function handleOptionLabelNombre(objeto) {
        return objeto.nombre
    }

    const autoCompleteObjectComparator = (option, value) => option.id === value.id

    const renderAutocompleteFilter = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }

    const handleAreaSelectedChange = (event, newValue) => {
        setAreaSelected(newValue ? newValue : null)
    }

    const handleInputAreaChange = (event, newValue) => {
        setInputArea(newValue)
    }

    const renderAreaFilter = () => {
        return (
            <Grid item xs={5} sm={4.75} md={2.625} lg={2.625} style={{ paddingBottom: '0.25rem' }}>
                {renderAutocompleteFilter(
                    'area-filter',
                    areaSelected,
                    handleAreaSelectedChange,
                    inputArea,
                    handleInputAreaChange,
                    areas,
                    handleOptionLabelNombre,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Area',
                    'small'
                )}
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        //if(tipoUnidadSelected !== null || marcaUnidadSelected !== null || placaFilter !== '' || empleadoSelected !== null) {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
        //}
    }

    const handleClearFilters = () => {
        setNombresFilter('')
        setApellidosFilter('')
        setDocIdentidadFilter('')
        setAreaSelected(null)
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={2.5} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderNombresFilter()}
                        {renderApellidosFilter()}
                        {renderDocIdentidadFilter()}
                        {renderAreaFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'colummnDocIdent', align: 'center', label: 'Doc. Ident.', minWidth: 75, format: 'string' },
        { id: 'columnEstado', align: 'center', label: 'Estado', minWidth: 75, format: 'string' },
        { id: 'columnNombres', align: 'center', label: 'Nombres y Apellidos', minWidth: 75, format: 'string' },
        { id: 'columnCelular', align: 'center', label: 'Celular', minWidth: 75, format: 'string' },
        { id: 'columnArea', align: 'center', label: 'Area', minWidth: 75, format: 'string' },
        { id: 'columnAcciones', align: 'center', label: 'Acciones', minWidth: 75, format: 'string' }
    ];

    const handleEditTableButton = (empleadoId) => {
        setSelectedEmpleadoId(empleadoId)
        setTitleDialog('Actualizar Empleado')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(false)
    }

    /*const handleDeleteTableButton = (empleadoId, empleadoDni) => {
        setSelectedEmpleadoId(empleadoId)
        setSelectedEmpleadoDni(empleadoDni)
        setOpenDeleteDialog(true)
    }*/

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {renderPagination()}
                </Paper>
            </Grid>
        )
    }

    const renderActionTableButtons = (empleado) => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
                <ThemeProvider theme={theme}>
                    {
                        permisos.includes(3) ?
                            <Grid item xs={12} sm={12} md={12} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton aria-label="edit" color="warning" onClick={() => handleEditTableButton(empleado.id)}><EditIcon fontSize="medium" /></IconButton>
                            </Grid> : null
                    }
                </ThemeProvider>
            </Grid>
        )
    }

    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(empleadosData === null) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (empleadosData.map(empleado => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${empleado.id}-${empleado.nroDocIdentidad}`}>
                                            <TableCell align='center'>
                                                {empleado.nroDocIdentidad}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Checkbox
                                                    id={`checkbox-${empleado.id}`}
                                                    checked={empleado.isContinue}
                                                    onChange={null}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    size='small'
                                                    disabled
                                                />
                                            </TableCell>
                                            <TableCell align='center'>
                                                {`${empleado.nombres} ${empleado.apellidos}`}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {empleado.celular}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {empleado.area.nombre}
                                            </TableCell>
                                            <TableCell key="options" align="center">
                                                {renderActionTableButtons(empleado)}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                <Table>
                    {(empleadosData === null) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (empleadosData.map(empleado => (
                            <TableBody key={`${empleado.id}-${empleado.placa}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 80px' }}>Doc. Ident:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{empleado.nroDocIdentidad}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 80px' }}>Estado:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>
                                                {empleado.isContinue ? 'Activo' : 'Inactivo'}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 80px' }}>Nombres:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{`${empleado.nombres} ${empleado.apellidos}`}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 80px' }}>Celular:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{empleado.celular}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 80px' }}>Area:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{empleado.area.nombre}</div>
                                        </div>
                                        {renderActionTableButtons(empleado)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const handleAddButton = () => {
        setTitleDialog('Registrar Empleado')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(true)
    }

    const renderAddButton = () => {
        return (
            <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: '0.6rem 0 0 0' }}>
                <Grid item>
                    <Button color="success" onClick={handleAddButton} variant="contained">Agregar Empleado</Button>
                </Grid>
            </Grid>
        )
    }

    const renderCreateUpdateDialog = () => {
        return (
            <Dialog open={openCreateUpdateDialog} onClose={() => setOpenCreateUpdateDialog(false)}>
                <EmpleadosCreateUpdate
                    title={titleDialog}
                    token={tokenActual}
                    isCreateDialog={isCreateDialog}
                    empleadoId={selectedEmpleadoId}
                    setDialogOpen={setOpenCreateUpdateDialog}
                    setSeverityAlert={setSeverityAlert}
                    setAlertMessage={setAlertMessage}
                    setOpenAlert={setOpenAlert}
                    reloadData={setActivateFilters}
                    flagForReloadData={activateFilters}
                    isSmUp={props.isSmUp}
                >
                </EmpleadosCreateUpdate>
            </Dialog>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    /*const handleDelete = async () => {
        try {
            //await UnidadesService.deleteUnidadById(tokenActual, selectedUnidadId);
            //manageAlert('success', 'Unidad con placa ' + selectedUnidadPlaca + ' eliminada exitosamente', true)
            handleClearFilters()
            setOpenDeleteDialog(false)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }*/

    /*const renderDeleteObject = (docIdentidad) => {
        return (
            <DeleteObjectDialog
                setOpen={setOpenDeleteDialog}
                deleteFunction={handleDelete}
                open={openDeleteDialog}
                title={'Deshabilitar empleado'}
                contentMessage={'¿Está seguro que desea dar de baja al empleado con documento: ' + docIdentidad + '?'}
            />
        )
    }*/

    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderData()}
            {permisos.includes(2) ? renderAddButton() : null}
            {renderCreateUpdateDialog()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
            {/*renderDeleteObject(selectedEmpleadoDni)*/}
        </Grid>
    );
};

export default Empleados;