import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from "@mui/material/Pagination";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Dialog from '@mui/material/Dialog'

import AutoComplete from '../../components/AutoComplete';
import UnidadesCreateUpdate from './UnidadesCreateUpdate';
import CustomizeSnackBar from '../../components/Snackbar';
import DeleteObjectDialog from '../../components/DeleteObjectDialog';

import UnidadesService from '../../services/unidades';
import TiposUnidadService from '../../services/tiposunidad';
import MarcasUnidadService from '../../services/marcasunidad';
import EmpleadosService from '../../services/empleados';

const Unidades = (props) => {

    const permisos = JSON.parse(window.localStorage.getItem('permisos')).Unidades;

    const [unidadesData, setUnidadesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [tiposUnidad, setTiposUnidad] = useState([])
    const [tipoUnidadSelected, setTipoUnidadSelected] = useState(null);
    const [inputTipoUnidad, setInputTipoUnidad] = useState('');

    const [marcasUnidad, setMarcasUnidad] = useState([])
    const [marcaUnidadSelected, setMarcaUnidadSelected] = useState(null);
    const [inputMarcaUnidad, setInputMarcaUnidad] = useState('');

    const [placaFilter, setPlacaFilter] = useState('')

    const [activateFilters, setActivateFilters] = useState(false)

    const [titleDialog, setTitleDialog] = useState('');
    const [openCreateUpdateDialog, setOpenCreateUpdateDialog] = useState(false);
    const [isCreateDialog, setIsCreateDialog] = useState(true);
    const [selectedUnidadId, setSelectedUnidadId] = useState(null)
    const [selectedUnidadPlaca, setSelectedUnidadPlaca] = useState(null)

    const [tokenActual, setTokenActual] = useState('');

    const [openAlert, setOpenAlert] = useState(false)
    const [severityAlert, setSeverityAlert] = useState('info')
    const [alertMessage, setAlertMessage] = useState('')

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        setTokenActual(loggedDashboardJSON)
        findAllUnidades(
            loggedDashboardJSON,
            currentPage - 1,
            tipoUnidadSelected ? tipoUnidadSelected.id : null,
            marcaUnidadSelected ? marcaUnidadSelected.id : null,
            placaFilter
        )
        if (props.mobileOpen === true) {
            props.onDrawerToggle()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, activateFilters]);

    useEffect(() => {
        const loggedDashboardJSON = window.localStorage.getItem('loggedDashboard').slice(1, -1)
        findAllTiposUnidad(loggedDashboardJSON)
        findAllMarcasUnidad(loggedDashboardJSON)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findAllUnidades = async (token, page, tipoId, marcaId, placa, choferId) => {
        try {
            const responseData = await UnidadesService.getUnidades(token, page, tipoId, marcaId, placa, choferId);
            setUnidadesData(responseData !== null ? responseData.content : null);
            setTotalPages(responseData !== null ? responseData.totalPages : 0);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const findAllTiposUnidad = async (token) => {
        try {
            const responseData = await TiposUnidadService.getTiposUnidad(token);
            setTiposUnidad(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const findAllMarcasUnidad = async (token) => {
        try {
            const responseData = await MarcasUnidadService.getMarcasUnidad(token);
            setMarcasUnidad(responseData);
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2FC6B1',
            },
            warning: {
                main: '#fbc02d'
            },
            addReg: {
                main: '#0d47a1',
                contrastText: '#fff',
            },
            refresh: {
                main: '#673ab7',
            }
        },
    });

    function handleOptionLabelDescripcion(objeto) {
        return objeto.descripcion
    }

    const autoCompleteObjectComparator = (option, value) => option.id === value.id

    const renderAutocompleteFilter = (id, value, onChange, inputValue, onInputChange, options, getOptionLabel, isOptionEqualToValue, fullWidth, width, label, size) => {
        return (
            <AutoComplete
                id={id}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth={fullWidth}
                width={width}
                label={label}
                size={size}
            />
        )
    }

    const handleTipoUnidadSelectedChange = (event, newValue) => {
        setTipoUnidadSelected(newValue ? newValue : null)
    }

    const handleInputTipoUnidadChange = (event, newValue) => {
        setInputTipoUnidad(newValue)
    }

    const renderTipoUnidadFilter = () => {
        return (
            <Grid item xs={6} sm={6} md={3.5} lg={3.5} style={{ paddingBottom: '0.25rem' }}>
                {renderAutocompleteFilter(
                    'tipounidad-filter',
                    tipoUnidadSelected,
                    handleTipoUnidadSelectedChange,
                    inputTipoUnidad,
                    handleInputTipoUnidadChange,
                    tiposUnidad,
                    handleOptionLabelDescripcion,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Tipo',
                    'small'
                )}
            </Grid>
        )
    }

    const handleMarcaUnidadSelectedChange = (event, newValue) => {
        setMarcaUnidadSelected(newValue ? newValue : null)
    }

    const handleInputMarcaUnidadChange = (event, newValue) => {
        setInputMarcaUnidad(newValue)
    }

    const renderMarcaUnidadFilter = () => {
        return (
            <Grid item xs={6} sm={6} md={3.5} lg={3.5} style={{ paddingBottom: '0.25rem' }}>
                {renderAutocompleteFilter(
                    'marcaunidad-filter',
                    marcaUnidadSelected,
                    handleMarcaUnidadSelectedChange,
                    inputMarcaUnidad,
                    handleInputMarcaUnidadChange,
                    marcasUnidad,
                    handleOptionLabelDescripcion,
                    autoCompleteObjectComparator,
                    true,
                    300,
                    'Marca',
                    'small'
                )}
            </Grid>
        )
    }

    const renderPlacaFilter = () => {
        return (
            <Grid item xs={9.5} sm={9.5} md={3.5} lg={3.5} style={{ paddingBottom: '0.25rem' }}>
                <TextField
                    id="placa-filter"
                    label="Placa"
                    variant="outlined"
                    value={placaFilter}
                    onChange={(event) => setPlacaFilter(event.target.value)}
                    size='small'
                    fullWidth>
                </TextField>
            </Grid>
        )
    }

    const handleApplyFilters = () => {
        //if(tipoUnidadSelected !== null || marcaUnidadSelected !== null || placaFilter !== '' || empleadoSelected !== null) {
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
        //}
    }

    const handleClearFilters = () => {
        setTipoUnidadSelected(null)
        setMarcaUnidadSelected(null)
        setPlacaFilter('')
        setActivateFilters(!activateFilters)
        setCurrentPage(1)
    }

    const renderFilterButtons = () => {
        return (
            <Grid item xs={2.5} sm={2.5} md={1.5} lg={1.5} style={{ paddingBottom: '0.25rem' }}>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleApplyFilters()} ><ManageSearchIcon /></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex" }}>
                        <IconButton aria-label="aplicar" color="primary" onClick={() => handleClearFilters()} ><ClearIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderFilters = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }} style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} md={12} >
                            Filtros
                        </Grid>
                        {renderTipoUnidadFilter()}
                        {renderMarcaUnidadFilter()}
                        {renderPlacaFilter()}
                        {renderFilterButtons()}
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const tableColumns = [
        { id: 'colummnTipo', align: 'center', label: 'Tipo', minWidth: 75, format: 'string' },
        { id: 'columnMarca', align: 'center', label: 'Marca', minWidth: 75, format: 'string' },
        { id: 'columnPlaca', align: 'center', label: 'Placa', minWidth: 75, format: 'string' },
        { id: 'columnAcciones', align: 'center', label: 'Acciones', minWidth: 75, format: 'string' }
    ];

    const handleEditTableButton = (unidadId) => {
        setSelectedUnidadId(unidadId)
        setTitleDialog('Actualizar Unidad')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(false)
    }

    const handleDeleteTableButton = (unidadId, unidadPlaca) => {
        setSelectedUnidadId(unidadId)
        setSelectedUnidadPlaca(unidadPlaca)
        setOpenDeleteDialog(true)
    }

    const renderData = () => {
        return (
            <Grid item xs={12} xm={12} md={12}>
                <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
                    {(props.isSmUp) ? (renderTable()) : (renderCards())}
                    {renderPagination()}
                </Paper>
            </Grid>
        )
    }

    const renderActionTableButtons = (unidad) => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} style={{ paddingTop: (props.isSmUp ? '0rem' : '0.5rem'), justifyContent: "center", alignContent: "center", alignItems: "center" }} >
                <ThemeProvider theme={theme}>
                    {
                        permisos.includes(3) ?
                            <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton aria-label="edit" color="warning" onClick={() => handleEditTableButton(unidad.id)}><EditIcon fontSize="medium" /></IconButton>
                            </Grid> : null
                    }
                    {
                        permisos.includes(4) ?
                            <Grid item xs={6} sm={6} md={6} style={{ justifyContent: "center", display: "flex", alignContent: "center", alignItems: "center" }}>
                                <IconButton aria-label="delete" color="error" onClick={() => handleDeleteTableButton(unidad.id, unidad.placa)}><DeleteIcon fontSize="medium" /></IconButton>
                            </Grid> : null
                    }
                </ThemeProvider>
            </Grid>
        )
    }

    const renderTable = () => {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={12}>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns
                                    .map((column) => (
                                        <TableCell
                                            key={`${column.id}-${column.label}`}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(unidadesData === null) ?
                                    (<TableRow hover role="checkbox" tabIndex={-1} key={'no-data'}>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                        <TableCell align='center'>---</TableCell>
                                    </TableRow>)
                                    :
                                    (unidadesData.map(unidad => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${unidad.id}-${unidad.placa}`}>
                                            <TableCell align='center'>
                                                {unidad.tipoUnidad.descripcion}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {unidad.marcaUnidad.descripcion}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {unidad.placa}
                                            </TableCell>
                                            <TableCell key="options" align="center">
                                                {renderActionTableButtons(unidad)}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    };

    const renderCards = () => {
        return (
            <TableContainer sx={{ /*maxHeight: 400*/ }}>
                <Table>
                    {(unidadesData === null) ?
                        (<TableBody key={'no-data'}>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell>---</TableCell>
                            </TableRow>
                        </TableBody>)
                        :
                        (unidadesData.map(unidad => (
                            <TableBody key={`${unidad.id}-${unidad.placa}`}>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 55px' }}>Tipo:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{unidad.tipoUnidad.descripcion}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 55px' }}>Marca:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{unidad.marcaUnidad.descripcion}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ flex: '0 0 55px' }}>Placa:</div>
                                            <div style={{ flex: '1', textAlign: 'left' }}>{unidad.placa}</div>
                                        </div>
                                        {renderActionTableButtons(unidad)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )))
                    }
                </Table>
            </TableContainer>
        )
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const renderPagination = () => {
        return (
            //<Grid container padding={'0.25rem'} style={{ borderTop: '1px solid #ccc' }}>
            <Grid container padding={'0.25rem'}>
                <Grid item xs={12} sm={12} md={12}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        variant="outlined"
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const handleAddButton = () => {
        setTitleDialog('Registrar Unidad')
        setOpenCreateUpdateDialog(true)
        setIsCreateDialog(true)
    }

    const renderAddButton = () => {
        return (
            <Grid container direction="row-reverse" justifyContent="rigth" alignItems="center" style={{ padding: '0.6rem 0 0 0' }}>
                <Grid item>
                    <Button color="success" onClick={handleAddButton} variant="contained">Agregar Unidad</Button>
                </Grid>
            </Grid>
        )
    }

    const renderCreateUpdateDialog = () => {
        return (
            <Dialog open={openCreateUpdateDialog} onClose={() => setOpenCreateUpdateDialog(false)}>
                <UnidadesCreateUpdate
                    title={titleDialog}
                    token={tokenActual}
                    unidadId={selectedUnidadId}
                    reloadData={setActivateFilters}
                    flagForReloadData={activateFilters}
                    isCreateDialog={isCreateDialog}
                    setDialogOpen={setOpenCreateUpdateDialog}
                    setOpenAlert={setOpenAlert}
                    setSeverityAlert={setSeverityAlert}
                    setAlertMessage={setAlertMessage}
                >
                </UnidadesCreateUpdate>
            </Dialog>
        )
    }

    const manageAlert = (severityAlert, alertMessage, openAlert) => {
        setSeverityAlert(severityAlert)
        setAlertMessage(alertMessage)
        setOpenAlert(openAlert)
    }

    const renderAlert = (setOpenAlert, openAlert, autoHideDuration, severity, variant, message) => {
        return (
            <CustomizeSnackBar
                setOpen={setOpenAlert}
                open={openAlert}
                autoHideDuration={autoHideDuration}
                severity={severity}
                variant={variant}
                message={message}
            >
            </CustomizeSnackBar>
        )
    }

    const handleDelete = async () => {
        try {
            await UnidadesService.deleteUnidadById(tokenActual, selectedUnidadId);
            manageAlert('success', 'Unidad con placa ' + selectedUnidadPlaca + ' eliminada exitosamente', true)
            handleClearFilters()
            setOpenDeleteDialog(false)
        }
        catch (error) {
            manageAlert('error', error.message, true)
        }
    }

    const renderDeleteObject = (placa) => {
        return (
            <DeleteObjectDialog
                setOpen={setOpenDeleteDialog}
                deleteFunction={handleDelete}
                open={openDeleteDialog}
                title={'Eliminar unidad'}
                contentMessage={'¿Está seguro que desea eliminar la unidad con placa: ' + placa + '?'}
            />
        )
    }

    return (
        <Grid container rowSpacing={2}>
            {renderFilters()}
            {renderData()}
            {permisos.includes(2)?renderAddButton():null}
            {renderCreateUpdateDialog()}
            {renderAlert(setOpenAlert, openAlert, 4000, severityAlert, 'standard', alertMessage)}
            {renderDeleteObject(selectedUnidadPlaca)}
        </Grid>
    );
};

export default Unidades;