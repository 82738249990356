const API_BASE_URL = `${process.env.REACT_APP_BACKEND_ALMACEN_URL}/ingreso-mercaderia`;

const manageResponse = async (response) => {
    const statusCode = response.status;

    if(statusCode === 200) 
        return await response.json();
    if(statusCode === 201)
        return await response.json();
    else if(statusCode === 204)
        return null;
    else if(statusCode === 400) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else if(statusCode === 401)
        throw new Error(`Error de autenticación, se recomienda reiniciar sesión`);
    else if(statusCode === 403)
        throw new Error(`No posee los permisos necesarios para realizar la petición`);
    else if(statusCode === 409) {
        const errorData = await response.json();
        const errorMessages = errorData.errorMessages;
        const errorMessage = errorMessages.map(message => `${message}`).join('. ');
        throw new Error(`Error al guardar: ${'\n'}${errorMessage}`);
    }
    else
        throw new Error(`Error al procesar la petición, contacte al administrador - ${statusCode}`);
}

const MercaderiaService = {
    getMercaderias: async (token, page, fechaIngreso, nroDocRemitente, nroDocDestinatario) => {
        try {
            const fechaIngresoFilter = fechaIngreso !== '' ? `&fechaIngreso=${fechaIngreso}` : ''
            const nroDocRemitenteFilter = nroDocRemitente !== '' ? `&nroDocRemitente=${nroDocRemitente}` : ''
            const nroDocDestinatarioFilter = nroDocDestinatario !== '' ? `&nroDocDestinatario=${nroDocDestinatario}` : ''
            const allfilters = fechaIngresoFilter + nroDocRemitenteFilter + nroDocDestinatarioFilter
            const response = await fetch(`${API_BASE_URL}?page=${page}${allfilters}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    getMercaderiaById: async (token, mercaderiaId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/${mercaderiaId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    create: async(token, mercaderia) => {
        try {
            const response = await fetch(`${API_BASE_URL}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(mercaderia)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    update: async(token, mercaderia) => {
        try {
            const response = await fetch(`${API_BASE_URL}/update`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(mercaderia)
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },

    deleteById: async (token, mercaderiaId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/delete/${mercaderiaId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    //'Content-Type': 'application/json',
                },
            });

            return manageResponse(response)
        }
        catch (error) {
            throw new Error(`${error.message}`);
        }
    },
}

export default MercaderiaService;